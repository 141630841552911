import React, { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from 'react-icons/md'
import { CleanButton } from './ui/kit/Button'
import Day, { DayProps } from './Day';
import { Theme } from '../style/Theme';

export type DaysWithHoly = (DayProps & { holy: boolean; type: string })[]

type DaysProps = {
    items: DaysWithHoly
}

const Days = ({ items }: DaysProps) => {
    const pastDays = items.filter(
        (item: { type: string }) => item.type === 'past'
    )
    const nonPastDays = items.filter(item => item.type !== 'past')
    const [hidePast, setHidePast] = useState(true)
    return (
        <div>
            <div>
                <div
                    css={(th: Theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: `${th.spacing.xs} ${th.spacing.sm}`,
                        background: th.colors.lightGray,
                        fontWeight: th.fontWeight.normal,
                        borderBottom: `1px solid ${th.colors.secondary}`,
                        position: 'relative'
                    })}
                >
                    <span>Predošlé sviatky</span>
                    <CleanButton
                        css={(th: Theme) => ({
                            position: 'absolute',
                            top: '50%',
                            right: th.spacing.sm,
                            transform: 'translate(0,-50%)'
                        })}
                        onClick={() => setHidePast(prev => !prev)}
                        title="Predošlé sviatky"
                    >
                        {hidePast ? (
                            <MdKeyboardArrowLeft />
                        ) : (
                            <MdKeyboardArrowDown />
                        )}
                    </CleanButton>
                </div>
                <div
                    css={() => ({
                        maxHeight: hidePast ? 0 : '100%',
                        overflow: 'hidden'
                    })}
                >
                    {pastDays.map(day => (
                        <Day {...day} key={`day-${day.id}`} />
                    ))}
                </div>
            </div>
            {nonPastDays.map(day => (
                <Day {...day} key={`day-${day.id}`} />
            ))}
        </div>
    )
}

export default Days
