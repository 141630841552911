import { ComponentType, ReactNode } from 'react'
import { Theme } from '../../../style/Theme'

type ButtonProps = {
    type?: 'primary' | 'secondary'
    children: ReactNode
    css?: object
    onClick: () => void
    enabled?: boolean
    submit?: boolean
    className?: string
    [key: string]: any
}

export type ButtonComp = ComponentType<ButtonProps>

const Button: ButtonComp = ({
    type = 'primary',
    children,
    css,
    onClick,
    enabled = true,
    submit = false,
    className,
    ...rest
}) => (
    <button
        className={className}
        onClick={onClick}
        disabled={!enabled}
        type={submit ? 'submit' : 'button'}
        css={(th: Theme) => ({
            backgroundColor:
                type === 'primary'
                    ? th.colors.green
                    : th.colors.secondary,
            color: th.colors.background,
            display: 'inline-block',
            cursor: 'pointer',
            outline: 0,
            whiteSpace: 'nowrap',
            padding: '0 22px',
            fontSize: '14px',
            height: '40px',
            lineHeight: '40px',
            fontWeight: th.fontWeight.semiBold,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            border: 'none',
            borderRadius: '3px',
            '&:hover': {
                opacity: '0.9'
            },
            ...css
        })}
        {...rest}
    >
        {children}
    </button>
)

export const CleanButton = ({
    children,
    onClick,
    className,
    ...rest
}: {
    children: ReactNode
    onClick: () => void
    className?: string
    [key: string]: any
}) => (
    <button
        className={className}
        css={(th: Theme) => ({
            background: 'transparent',
            border: 'none',
            fontSize: th.fontSize.lg,
            lineHeight: th.fontSize.md,
            cursor: 'pointer',
            color: th.colors.background,
            padding: 0
        })}
        onClick={onClick}
        {...rest}
    >
        {children}
    </button>
)

export default Button
