import React, { useState } from 'react'
import { MdInfoOutline, MdInfo } from 'react-icons/md'
import { CleanButton } from './ui/kit/Button'
import { Theme } from '../style/Theme'

export type DayProps = {
    id: number
    name: string
    date: string
    day: string
    info: string
}

type DayCompProps = DayProps & {
    type?: string
    holy: boolean
}

const Day = ({ name, date, day, type, holy, info }: DayCompProps) => {
    const tmp = date.split('-')
    const displayDate = `${tmp[2]}.${tmp[1]}.${tmp[0]}`
    const [showDetail, setShowDetail] = useState(false)
    return (
        <div
            css={(th: Theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: `${th.spacing.xs} ${th.spacing.sm}`,
                background:
                    type === 'past'
                        ? th.colors.lightGray
                        : type === 'next'
                        ? th.colors.green
                        : 'transparent',
                fontWeight: holy ? th.fontWeight.bold : th.fontWeight.normal,
                borderBottom: `1px solid ${th.colors.secondary}`,
                position: 'relative'
            })}
            title={`${holy ? 'Prikázaný' : 'Neprikázaný'} sviatok`}
        >
            <CleanButton
                onClick={() => setShowDetail(prev => !prev)}
                css={(th: Theme) => ({
                    position: 'absolute',
                    top: '14px',
                    right: th.spacing.sm
                })}
                title={`Info o ${name}`}
            >
                {showDetail ? <MdInfo /> : <MdInfoOutline />}
            </CleanButton>
            <span>
                {displayDate} ({day.toLowerCase()})
            </span>
            <span css={() => ({ textAlign: 'center' })}>
                {name}
                {!holy && ' [Neprikázaný]'}
            </span>
            <div
                css={() => ({
                    maxHeight: showDetail ? '100%' : '0',
                    overflow: 'hidden'
                })}
            >
                <p
                    css={(th: Theme) => ({
                        fontWeight: th.fontWeight.normal,
                        marginTop: th.spacing.xs,
                        textAlign: 'center',
                        whiteSpace: 'pre-wrap',
                        fontStyle: 'italic'
                    })}
                >
                    {info}
                </p>
            </div>
        </div>
    )
}

export default Day
