import { css } from '@emotion/react'

export const theme = {
    colors: {
        primary: '#ffffff',
        secondary: '#09202b',
        green: '#35b363',
        gray: '#555555',
        lightGray: '#b0b0b0',
        mediumGray: '#aaaaaa',
        background: '#000000',
        footer: {
            primary: '#000000',
            secondary: '#282828',
            primaryText: '#cccccc',
            secondaryText: '#ffffff40'
        },
        accordion: '#eeeeee'
    },
    fonts: {
        primary: 'Roboto, sans-serif'
    },
    fontSize: {
        lg: '1.5rem',
        md: '1rem',
        small: '0.9rem',
        xsmall: '0.8rem'
    },
    fontWeight: {
        normal: 400,
        semiBold: 600,
        bold: 700
    },
    spacing: {
        xxs: '0.25rem',
        xs: '0.5rem',
        sm: '1rem',
        md: '2rem',
        lg: '3rem',
        xl: '4rem',
        xxl: '5rem',
        x3l: '6rem',
        x4l: '7rem',
        x5l: '8rem'
    },
    container: {
        width: '1170px'
    },
    zIndex: {
        nav: 10
    },
    mediaQuery: {
        sm: '480px',
        md: '768px',
        lg: '1024px',
        xl: '1200px'
    }
}

export const globalCss = css`
    body {
        font-family: ${theme.fonts.primary};
        color: ${theme.colors.secondary};
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        * {
            font-family: ${theme.fonts.primary};
        }
    }
    a {
        color: #000;
        text-decoration: none;
    }
`
