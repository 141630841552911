// @ts-ignore
import favicon from './favicon.png'
// @ts-ignore
import logo from './logo.png'
// @ts-ignore
import bannerSmall from './banner_small.jpg'
// @ts-ignore
import bannerBig from './banner_big.jpg'

const imgs = {
    favicon,
    logo,
    bannerBig,
    bannerSmall
}
export default imgs
