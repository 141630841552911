
// const NavItem = ({ page }: { page: LabeledPage }) => {
//     const theme: any = useTheme()
//     return (
//         <Link
//             to={page.path}
//             css={{
//                 color: theme.colors.primary,
//                 textTransform: 'uppercase',
//                 textAlign: 'center',
//                 fontWeight: 700,
//                 fontSize: '13px',
//                 padding: `0 ${theme.spacing.md}`,
//                 '&:not(:first-of-type)': {
//                     marginLeft: '2px'
//                 },
//                 lineHeight: '22px',
//                 letterSpacing: '1px',
//                 '&:hover': {
//                     color: theme.colors.primary
//                 }
//             }}
//             activeStyle={{ color: theme.colors.green }}
//         >
//             {page.label}
//         </Link>
//     )
import React from 'react';
import { Link } from "react-router-dom"
import { Theme } from "../style/Theme"

// }
type Page = {
    path: string
}

export type LabeledPage = Page & {
    label: string
}
const Navigation = () => {
    const year = new Date().getFullYear()
    return (
        <>
            <nav
                css={(theme: Theme) => ({
                    backgroundColor: theme.colors.secondary,
                    padding: '1rem',
                    position: 'sticky',
                    top: 0,
                    boxShadow: '0 0 10px rgba(0,0,0,.1)',
                    zIndex: 100
                })}
            >
                <div
                    css={(th: Theme) => ({
                        margin: '0 auto',
                        maxWidth: '1100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: `0 ${th.spacing.sm}`
                    })}
                >
                    <Link to="/">
                        <h1
                            css={(th: Theme) => ({
                                color: th.colors.primary,
                                fontWeight: th.fontWeight.bold,
                                fontSize: '1.5em',
                                display: 'flex',
                                alignItems: 'flex-end'
                            })}
                        >
                            {/* <img
                                src={media.logo}
                                alt="Prikazane-sviatky.sk"
                                css={theme => ({
                                    width: '30px',
                                    marginRight: '0.5rem'
                                })}
                            /> */}
                            <span>Prikázané Sviatky {year}</span>
                        </h1>
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Navigation
