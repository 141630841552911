import React, { useState } from 'react'
import Container from './components/Container'
import { sortAndMarkDays } from './utils'
import Days, { DaysWithHoly } from './components/Days'
import {  css } from '@emotion/react'
import { DayProps } from './components/Day'
import Button from './components/ui/kit/Button'
import { About } from './components/About'
import { Link } from 'react-router-dom'
import { Theme } from './style/Theme'
import data from './data/days.json'


type DataType = {
    holyDays: DayProps[]
    unholyDays: DayProps[]
}

const App = () => {
    const [showAll, setShowAll] = useState(false)
    const { holyDays, unholyDays } = data as DataType
    const holyTmp = holyDays.map(d => {
        // @ts-ignore
        d.holy = true
        return d
    }) as DaysWithHoly
    const unholyTmp = unholyDays.map(d => {
        // @ts-ignore
        d.holy = false
        return d
    }) as DaysWithHoly
    const days = showAll ? holyTmp.concat(unholyTmp) : holyTmp
    const items = sortAndMarkDays(days)
    const year = new Date().getFullYear()
    return (
        <Container>
            <div
                css={() => ({
                    position: 'absolute',
                    opacity: 0,
                    zIndex: -5
                })}
            >
                <h2>Prikázané sviatky na rok {year}</h2>
                <h3>Veľkonočné sviatky</h3>
                <h3>Vianočné sviatky</h3>
            </div>
            <Days items={items} />

            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    padding-top: 1rem;
                `}
            >
                <Button onClick={() => setShowAll(!showAll)}>
                    {showAll
                        ? 'Zobraziť iba prikázané sviatky'
                        : 'Zobraziť aj neprikázané sviatky'}
                </Button>
            </div>
            {/* {showAll && ( */}
            {/*    <Button */}
            {/*        onClick={() => setShowAll(false)} */}
            {/*        css={(th: Theme) => ({ */}
            {/*            position: 'fixed', */}
            {/*            bottom: th.spacing.xl, */}
            {/*            right: th.spacing.sm, */}
            {/*            borderRadius: '50%', */}
            {/*            padding: th.spacing.xs, */}
            {/*            height: th.spacing.lg, */}
            {/*            width: th.spacing.lg, */}
            {/*            fontSize: th.fontSize.lg, */}
            {/*            display: 'flex', */}
            {/*            alignItems: 'center', */}
            {/*            justifyContent: 'center', */}
            {/*            boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.5)' */}
            {/*        })} */}
            {/*    > */}
            {/*        <MdClose /> */}
            {/*    </Button> */}
            {/* )} */}
            <About />
            <div
                css={(theme: Theme) => ({
                    marginBottom: theme.spacing.sm,
                    '@media (max-width: 768px)': {
                        padding: `0 ${theme.spacing.sm}`
                    }
                })}
            >
                <Link
                    to="/osobne-udaje"
                    css={(theme: Theme) => ({
                        color: theme.colors.green,
                        padding: `0 ${theme.spacing.sm}`
                    })}
                >
                    Zásady ochrany osobných údajov
                </Link>
            </div>
        </Container>
    )
}

export default App
