import React, { ReactNode } from 'react'

type ContainerProps = {
    children: ReactNode
    css?: object
}

const Container = ({ children, css, ...props }: ContainerProps) => (
    <div
        {...props}
        css={theme => ({
            margin: '0 auto',
            maxWidth: '1100px',
            ...css
        })}
    >
        {children}
    </div>
)

export default Container
