import React from 'react'
import { Theme } from '../style/Theme'

export const About = () => {
    const year = new Date().getFullYear()
    return (
        <div
            css={(th: Theme) => ({
                marginTop: th.spacing.md,
                marginBottom: th.spacing.sm,
                padding: `0 ${th.spacing.sm}`,
            })}
        >
            <h2
                css={(theme: Theme) => ({
                    textAlign: 'center',
                    fontWeight: theme.fontWeight.semiBold,
                    fontSize: theme.fontSize.lg,
                    marginBottom: theme.spacing.sm
                })}
            >
                O projekte
            </h2>
            <p
                css={(theme: Theme) => ({
                    textAlign: 'justify',
                    fontSize: theme.fontSize.sm,
                    marginBottom: theme.spacing.sm
                })}
            >
                Vitajte na našej webovej stránke, na ktorej nájdete zoznam
                prikázaných (aj neprikázaných) kresťanských sviatkov pre rok{' '}
                {year}. Na aký dátum vychádza Popolcová streda? Ktorý deň je
                Štedrý deň? Chápeme dôležitosť sledovania cirkevných sviatkov a
                zároveň, ako je niekedy zložité zistiť, kedy dané sviatky
                slávime. Naša jednoducho použiteľná stránka umožňuje rýchlo a
                ľahko zobraziť všetky dôležité dátumy a dni prikázaných a
                neprikázaných kresťanských sviatkov pre aktuálny rok, takže už
                nikdy nebudete mať problém sa v dátumoch zorientovať. Naša
                webová stránka vám tiež umožňuje dozvedieť sa viac o histórii a
                význame každého sviatku, čím poskytuje hlbšie pochopenie a
                váženie si týchto špeciálnych dní.
            </p>
            <p
                css={(theme: Theme) => ({
                    textAlign: 'justify',
                    fontSize: theme.fontSize.sm
                    // marginBottom: theme.spacing.sm
                })}
            >
                Snažíme sa udržiavať naše informácie presné a aktuálne, čím
                zabezpečujeme, že môžete dôverovať informáciám, ktoré
                poskytujeme. Či už ste vytrvalým kresťanom alebo len hľadáte
                spôsob, ako sledovať dôležité náboženské sviatky, naša webová
                stránka je pre vás perfektným zdrojom. Táto stránka obsahuje
                zoznam všetkých prikázaných aj neprikázaných sviatkov, poctivo
                vypočítaných zo zdroja{' '}
                <a
                    css={(theme: Theme) => ({
                        color: theme.colors.green
                    })}
                    href="https://kbs.sk"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    kbs.sk
                </a>
                .
            </p>
        </div>
    )
}
