import { DaysWithHoly } from "../components/Days"


export const sortAndMarkDays = (days: DaysWithHoly) => {
    let nextUsed = false
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    // @ts-ignore
    const sortedDays = days.sort((a, b) => new Date(a.date) - new Date(b.date))
    const mappedDays = sortedDays.map(item => {
        const dayDate = new Date(item.date)
        dayDate.setHours(0, 0, 0, 0)
        console.log()
        let type = 'future'
        const dayDateTime = dayDate.getTime();
        const todayTime = today.getTime();
        if (dayDate < today) type = 'past'
        else if (dayDateTime === todayTime) {
            type = 'next'
            nextUsed = true
        }
        else if (dayDateTime > todayTime && nextUsed) {
            type = 'future'
        }
        else if (dayDateTime > todayTime && !nextUsed) {
            nextUsed = true
            type = 'next'
        }
        return { ...item, type }
    })
    // @ts-ignore
    return mappedDays.sort((a, b) => new Date(a.date) - new Date(b.date))
}
