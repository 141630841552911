import { Global } from "@emotion/react";
import 'minireset.css';
import { Helmet } from 'react-helmet';
import media from '../img/media';
import Navigation from "../navigation/Navigation";
import { globalCss } from "../style/styles";


const Layout = ({ children }: { children: any }) => {
    const year = new Date().getFullYear()
    const title = `Prikázané sviatky pre rok ${year}`
    const description = `Aktuálny zoznam prikázaných sviatkov rímskokatolíckej cirkvi na rok ${year}. Prikázané sviatky, Veľkonočne sviatky, vianočné sviatky aj rôzne pohyblivé.`
    // const [showAds] = useState(true)
    // useEffect(() => {
    //     if (showAds) {
    //         try {
    //             // @ts-ignore
    //             ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    //         } catch (e) {}
    //     }
    // }, [showAds])
    return (
        <div>
            <Global styles={globalCss} />
            <Helmet>
                <html lang="sk" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <link
                    rel="icon"
                    type="image/png"
                    href={media.favicon}
                    sizes="16x16"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#fff" />
                <meta name="robots" content="index, follow" />
                <meta charSet="UTF-8" />
                <link rel="canonical" href="https:/prikazane-sviatky.sk" />
                {/* {!showAds && (
                    <style>{`.adsbygoogle {
                                display: none!important;
                            }
                            body {
                                padding:0!important;
                            }`}</style>
                )} */}
            </Helmet>
            <div
                css={() => ({
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                })}
            >
                <Navigation />
                <div style={{ flex: '1' }}>{children}</div>
                {/* {showAds && (
                //     <ins
                //         className="adsbygoogle"
                //         style={{
                //             display: 'block',
                //             position: 'sticky',
                //             bottom: 0,
                //             left: 0
                //         }}
                //         data-ad-format="fluid"
                //         data-ad-layout-key="-fb+5w+4e-db+86"
                //         data-ad-client="ca-pub-7107296404320558"
                //         data-ad-slot="6897263417"
                //     />
                )} */}
                {/* <CookieBanner
                    message="Táto webstránka používa súbory cookies.
Na prispôsobenie obsahu a reklám a analýzu návštevnosti používame súbory cookie. Informácie o tom, ako používate naše webové stránky, poskytujeme aj našim partnerom v oblasti sociálnych médií, inzercie a analýzy. Títo partneri môžu príslušné informácie skombinovať s ďalšími údajmi, ktoré ste im poskytli alebo ktoré od vás získali, keď ste používali ich služby."
                    wholeDomain={true}
                    acceptButtonText="Prijať"
                    declineButtonText="Odmietnuť"
                    managePreferencesButtonText="Prispôsobiť"
                    necessaryOptionText="Nevyhnutné"
                    privacyPolicyLinkText="Zásady ochrany osobných údajov"
                    savePreferencesButtonText="Uložiť"
                    onDeclineMarketing={() => setShowAds(false)}
                    onAcceptMarketing={() => setShowAds(true)}
                    policyLink={'/osobne-udaje'}
                    showStatisticsOption={false}
                    showPreferencesOption={false}
                    marketingDefaultChecked
                    styles={{
                        dialog: {
                            position: 'sticky',
                            left: 0,
                            bottom: 0,
                            zIndex: 100000,
                            backgroundColor: theme.colors.secondary,
                            padding: '10px'
                        },
                        message: {
                            color: 'white',
                            fontSize: theme.fontSize.small,
                            padding: '10px'
                        },
                        policy: {
                            marginLeft: '10px',
                            fontSize: theme.fontSize.small,
                            textDecoration: 'underline',
                            color: 'white'
                        },
                        button: {
                            color: theme.colors.secondary,
                            background: 'white',
                            border: 'none',
                            borderRadius: '3px',
                            fontSize: theme.fontSize.md,
                            cursor: 'pointer',
                            padding: '8px'
                        },
                        buttonWrapper: {
                            display: 'flex',
                            float: 'right',
                            gap: '12px'
                        }
                    }}
                /> */}
            </div>
        </div>
    )
}

export default Layout
