import Container from '../components/Container'
import { Theme } from '../style/Theme'

const PrivacyPage = () => {
    return (
        <Container
            css={(th: Theme)  => ({
                marginTop: th.spacing.md,
                marginBottom: th.spacing.sm,
                padding: `0 ${th.spacing.sm}`
            })}
        >
            <h2
                css={(th: Theme)  => ({
                    fontWeight: th.fontWeight.bold,
                    fontSize: th.fontSize.lg,
                    textAlign: 'center',
                    marginBottom: th.spacing.sm
                })}
            >
                Zásady ochrany osobných údajov
            </h2>
            <p>
                V spoločnosti Prikazane-sviatky - prevádzkovateľovi tejto
                webovej stránky , chápeme dôležitosť ochrany vašich osobných
                údajov a súkromia. Táto zásada ochrany osobných údajov opisuje
                informácie, ktoré zbierame a ako ich používame.
            </p>
            <h3
                css={(th: Theme)  => ({
                    fontWeight: th.fontWeight.bold,
                    fontSize: '1.2rem',
                    marginTop: th.spacing.sm,
                    marginBottom: th.spacing.sm
                })}
            >
                Zbieranie a použitie informácií:
            </h3>
            <p>
                Zbierame informácie od návštevníkov našej webovej stránky
                prostredníctvom použitia súborov cookie spoločnosti Google
                Adsense. Tieto informácie zahŕňajú, ale nie sú obmedzené na vašu
                IP adresu a polohu, typ prehliadača a operačný systém. Tieto
                informácie používame na zlepšenie výkonu a používateľského
                zážitku našej webovej stránky.
            </p>
            <h3
                css={(th: Theme)  => ({
                    fontWeight: th.fontWeight.bold,
                    fontSize: '1.2rem',
                    marginTop: th.spacing.sm,
                    marginBottom: th.spacing.sm
                })}
            >
                Zdieľanie dát:
            </h3>

            <p>
                Nepredávame, neprenajímame ani nedávame ďalej vaše osobné údaje
                tretím stranám. Informácie získané prostredníctvom súborov
                cookie spoločnosti Google Adsense môžu byť zdieľané s reklamnou
                sieťou na zobrazenie relevantných reklám.
            </p>

            <h3
                css={(th: Theme)  => ({
                    fontWeight: th.fontWeight.bold,
                    fontSize: '1.2rem',
                    marginTop: th.spacing.sm,
                    marginBottom: th.spacing.sm
                })}
            >
                Uchovávanie dát:
            </h3>

            <p>
                Uchovávame dáta získané prostredníctvom súborov cookie
                spoločnosti Google Adsense tak dlho, ako je to potrebné na
                poskytovanie našich služieb a zlepšenie používateľského zážitku
                našej webovej stránky.
            </p>

            <h3
                css={(th: Theme)  => ({
                    fontWeight: th.fontWeight.bold,
                    fontSize: '1.2rem',
                    marginTop: th.spacing.sm,
                    marginBottom: th.spacing.sm
                })}
            >
                Zmeny v zásade ochrany osobných údajov:
            </h3>

            <p>
                Vyhradzujeme si právo aktualizovať túto zásadu ochrany osobných
                údajov kedykoľvek bez predchádzajúceho upozornenia. Všetky zmeny
                budú zobrazované na tejto stránke a vaše ďalšie používanie našej
                webovej stránky signalizuje vaše prijatie aktualizovanej
                politiky.
            </p>
        </Container>
    )
}

export default PrivacyPage
